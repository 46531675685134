export const localization = {
    "kz": {
        share: "Бөлісу",
        copy:"Сілтемені көшіру",
        shareText: "Бұл жетістікті әлеуметтік желіде бөлісіңіз",
        emailSubject: "Платформаға шағымдау",
        tgTitle: "Jasaim | Менің NFT дипломымды бұл сілтеме арқылы көруге болады: ",
        wpTitle: "Jasaim | Менің NFT дипломымды бұл сілтеме арқылы көруге болады: ",
        liTitle: "Платформаға шағымдау",
        liSummary: "Платформаға шағымдау",
    },
    "ru": {
        share:"Поделиться",
        copy:"Скопировать ссылку",
        shareText:"Поделитесь этим достижением в социальной сети",
        emailSubject:"Приглашение на платформу",
        tgTitle: "Jasaim | Мой NFT диплом доступен к просмотру по данной ссылке: ",
        wpTitle: "Jasaim | Мой NFT диплом доступен к просмотру по данной ссылке: ",
        liTitle: "Приглашение на платформу",
        liSummary: "Приглашение на платформу",
    },
    "en": {
        share: "Share",
        copy:"Copy link",
        shareText: "Share this achievement on social media",
        emailSubject: "Invitation to the platform",
        tgTitle: "Jasaim | My NFT diploma is available for view at this link: ",
        wpTitle: "Jasaim | My NFT diploma is available for view at this link: ",
        liTitle: "Invitation to the platform",
        liSummary: "Invitation to the platform",
    },
}