export const localization = {
    'kz': {
        appplications: 'Өтінімдер',
        allApplications: 'Барлық өтінімдер',
        inviteds: 'Шақырылғандар',
        rejecteds: 'Қабылданбады',
        fullname: 'Толық аты',
        speciality: 'Мамандық',
        graduationYear: 'Аяқтау жылы',
        date: 'Өтінім берілген күні',
        status: 'Мәртебесі',
        invited: 'Шақырылды',
        rejected: 'Қабылданбады',
        job: 'Жұмыс',
        myApplications: 'Менің өтінімдерім',
        employers: 'Жұмыс берушілер',
        student: 'Студент',
        invite: 'Шақыру',
        reject: 'Қабылдау',
        goToProfile: 'Профильге өту',
        dateofbirth: 'Туған күні:',
        region: 'Облыс:',
        email: 'Электрондық пошта:',
        phone: 'Телефон нөмірі:',
        university: 'Университет атауы:',
        specialityName: 'Мамандық:',
        degree: 'Дәреже:',
        rating: 'Академиялық рейтинг:',
        skills: 'Дағдылар',
    },
    'ru': {
        appplications: 'Заявки',
        allApplications: 'Все заявки',
        inviteds: 'Приглашенные',
        rejecteds: 'Отказанные',
        fullname: 'ФИО',
        speciality: 'Специальность',
        graduationYear: 'Год выпуска',
        date: 'Дата отклика',
        status: 'Статус',
        invited: 'Приглашен',
        rejected: 'Отказан', 
        job: 'Работа',
        myApplications: 'Мои отклики',
        employers: 'Работодатели',
        student: 'Студент',
        invite: 'Пригласить',
        reject: 'Отказать',
        goToProfile: 'Перейти в профиль',
        dateofbirth: 'Дата рождения:',
        region: 'Регион:',
        email: 'Почта:',
        phone: 'Номер телефона:',
        university: 'Название вуза:',
        specialityName: 'Специальность:',
        degree: 'Степень:',
        rating: 'Академичиский Рейтинг:',
        skills: 'Навыки',
    },
    'en': {
        appplications: 'Applications',
        allApplications: 'All Applications',
        inviteds: 'Invited',
        rejecteds: 'Rejected',
        fullname: 'Full Name',
        speciality: 'Specialty',
        graduationYear: 'Graduation Year',
        date: 'Response Date',
        status: 'Status',
        invited: 'Invited',
        rejected: 'Rejected',
        job: 'Job',
        myApplications: 'My Applications',
        employers: 'Employers',
        student: 'Student',
        invite: 'Invite',
        reject: 'Reject',
        goToProfile: 'Go to Profile',
        dateofbirth: 'Date of Birth:',
        region: 'Region:',
        email: 'Email:',
        phone: 'Phone Number:',
        university: 'University Name:',
        specialityName: 'Specialty:',
        degree: 'Degree:',
        rating: 'Academic Rating:',
        skills: 'Skills',
    }
    
}