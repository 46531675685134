import React from 'react';
import ServerPageLayout from './ServerpageLayout';

const ServerPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<ServerPageLayout />
		</React.Fragment>
	);
};

export default ServerPageContainer;