export const modelType = {
	"Student": {
		label: {
			ru: 'Ф.И.О',
			en: 'Full Name',
			kz: 'Аты-жөні'
		},
		inputType: "text",
		inputName: "name",
		placeholder: {
			ru: 'Полное имя',
			en: 'Enter your full name',
			kz: 'Аты-жөніңізді енгізіңіз'
		}
	},
	"Employer": {
		label: {
			ru: 'Название Компании',
			en: 'Company Name',
			kz: 'Компания аты'
		},
		inputType: "text",
		inputName: "name",
		placeholder: {
			ru: 'Введите название компании',
			en: 'Enter the company name',
			kz: 'Компанияның атын енгізіңіз'
		}
	},
	"University": {
		label: {
			ru: 'Название Университета',
			en: 'University Name',
			kz: 'Университет аты'
		},
		inputType: "text",
		inputName: "name",
		placeholder: {
			ru: 'Введите название университета',
			en: 'Enter the university name',
			kz: 'Университеттің атын енгізіңіз'
		}
	}
};


export const localization = {
	Register: {
		ru: 'Зарегистрироваться',
		en: 'Register',
		kz: 'Тіркелу',
	},
	EmailHint: {
		ru: "Введите свой адрес электронной почты и пароль для входа в систему!",
		en: "Please enter your email address and password for login into your account!",
		kz: "Кіру үшін электрондық пошта мен құпия сөзді енгізіңіз!"
	},
	Student: {
		ru: 'Студент',
		en: 'Student',
		kz: 'Студент'
	},
	Company: {
		ru: 'Компания',
		en: 'Company',
		kz: 'Компания'
	},
	University: {
		ru: 'Университет',
		en: 'University',
		kz: 'Университет'
	},
	email: {
		ru: {
			label: 'Почта',
			placeholder: 'Логин или Email',
		},
		en: {
			label: 'Email',
			placeholder: 'Login or Email',
		},
		kz: {
			label: 'Электрондық пошта',
			placeholder: 'Логин немесе электрондық пошта',
		},
	},
	name: {
		ru: {
			label: 'Ф.И.О',
			placeholder: 'Полное имя',
		},
		en: {
			label: 'Full name',
			placeholder: 'Enter full name',
		},
		kz: {
			label: 'Т.А.Ә',
			placeholder: 'Толық атыңыз',
		},
	},
	selectEDS: {
		ru: 'Выбрать ключ ЭЦП',
		en: 'Select EDS',
		kz: 'ЭЦП таңдау',
	},
	sendCode: {
		ru: 'Отправить код',
		en: 'Send password',
		kz: 'Кодты жіберу',
	},
	have: {
		ru: "У вас уже есть учетная запись?",
		en: "You already have an account?",
		kz: "Сізде есептік жазба бар ма?"
	},
	inside: {
		ru: "Войдите в профиль",
		en: "Sign in to your account",
		kz: "Есептік жазбаға кіру"
	},
}
