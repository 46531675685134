import React from 'react';
import { EmployerDetailsPageLayout } from './EmployerDetailsPageLayout';

const EmployerDetailsPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<EmployerDetailsPageLayout/>
		</React.Fragment>
	);
};

export default EmployerDetailsPageContainer;