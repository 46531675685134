import React from 'react';

import { AboutUsPageLayout } from './AboutUsPageLayout';

const AboutUsPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<AboutUsPageLayout/>
		</React.Fragment>
	);
};
export default AboutUsPageContainer;