export const localization = {
	Copyright: {
		ru: '© 2023 Все права защищены',
		en: '© 2023 All rights reserved',
		kz: '© 2023 Барлық құқықтар қорғалған',
		link: '#',
	},
	PrivacyPolicy: {
		ru: 'Политика конфиденциальности',
		en: 'Privacy Policy',
		kz: 'Құпиялылық саясаты',
		link: '#',
	},
	UserAgreement: {
		ru: 'Пользовательское соглашение',
		en: 'User Agreement',
		kz: 'Пайдаланушы келісімі',
		link: '#',
	},
	Help: {
		ru: 'Помощь',
		en: 'Help',
		kz: 'Көмек',
		link: '#',
	},
	signIn: {
		ru: 'Войти',
		en: 'Sign In',
		kz: 'Кіру',
	},
	Register: {
		ru: 'Регистрация',
		en: 'Register',
		kz: 'Тіркеу',
	},
	EmailHint: {
		ru: "Введите свой адрес электронной почты и пароль для входа в систему!",
		en: "Please enter your email address and password for login into your account!",
		kz: "Кіру үшін электрондық пошта мен құпия сөзді енгізіңіз!"
	},
	email: {
		ru: {
			label: 'Почта',
			placeholder: 'Логин или Email',
		},
		en: {
			label: 'Email',
			placeholder: 'Login or Email',
		},
		kz: {
			label: 'Электрондық пошта',
			placeholder: 'Логин немесе электрондық пошта',
		},
	},
	password: {
		ru: {
			label: 'Пароль',
			placeholder: 'Пароль',
		},
		en: {
			label: 'Password',
			placeholder: 'Password',
		},
		kz: {
			label: 'Құпия сөз',
			placeholder: 'Құпия сөз',
		},
	},
	rememberMe: {
		ru: 'Запомнить меня',
		en: 'Remember Me',
		kz: 'Мені есте сақтаңыз',
	},
	forgotP: {
		ru: 'Забыли пароль',
		en: 'Forgot password',
		kz: 'Құпия сөз умытттыныз',
	},
	notRegistered: {
		ru: 'Eще не зарегистрированы?',
		en: 'Still not registered?',
		kz: 'Әлі тіркелмедіңіз ба?',
	},
	createAccount: {
		ru: 'Создать учетную запись',
		en: 'Create an account',
		kz: 'Жаңа аккаунт тіркеу',
	},
	selectEDS: {
		ru: 'Выбрать ключ ЭЦП',
		en: 'Select EDS',
		kz: 'ЭЦП таңдау',
	}

};