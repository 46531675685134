import React from 'react';
import {Select as MuiSelect, styled, FormControl, Box} from '@mui/material';

import {SelectProps} from './Select.props';
import {Label} from '../Label/Label';
import {ReactComponent as EditIcon} from "@src/assets/icons/edit.svg";
import DeleteIcon from '@src/assets/icons/deleteIcon.png';

import {NavLink} from 'react-router-dom';


const CustomSelect = styled(MuiSelect, {
    shouldForwardProp: (prop) => prop !== 'selectSize',
})<{ selectSize?: 's' | 'm' | 'l' }>(({selectSize, theme}) => ({
    borderRadius: '48px',
    '& .MuiSelect-select': {
        fontWeight: '400',
        color: theme.palette.text.primary,
        borderRadius: '48px',
        padding: selectSize === 's' ? '13.5px 20px' : '10px 20px',
        backgroundColor: '#F8F8F8',
    },
}));

export const Select: React.FC<SelectProps> = (props) => {
    const {
        children,
        placeholder,
        label,
        helper,
        fullWidth,
        edit,
        onDelete,
        selectSize = 's',
        ...otherProps
    } = props;
    return (
        <FormControl fullWidth={fullWidth}>
            {label && <Label label={label} helper={helper}/>}
            <Box display="flex" alignItems="center" borderRadius="48px">
                {edit ?
                    <Box mr="2px" bgcolor="#E8E8E9" padding="4.5px" borderRadius="48px"
                         sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>
                        <NavLink to={edit}>
                            <EditIcon/>
                        </NavLink>
                    </Box>
                    : null}
                <CustomSelect
                    sx={{borderTopLeftRadius: edit ? 0 : "auto", borderBottomLeftRadius: edit ? 0 : "auto"}}
                    fullWidth={fullWidth}
                    disableUnderline
                    size='small'
                    variant='filled'
                    selectSize={selectSize}
                    {...otherProps}>
                    {children}
                </CustomSelect>
                {onDelete
                    ? <div><img src={DeleteIcon} alt="dlti" style={{cursor: "pointer", marginLeft: "5px"}}
                                onClick={onDelete}/></div>
                    : null}
            </Box>
        </FormControl>
    );
};
