import React from 'react';
import { AIChatLayout } from './AIChatPageLayout';

const AIChatContainer: React.FC = () => {

	return (
		<React.Fragment>
			<AIChatLayout/>
		</React.Fragment>
	);
};

export default AIChatContainer;