import React from 'react';
import { MyDiplomasPageLayout } from './MyDiplomasPageLayout';

const MyDiplomasPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<MyDiplomasPageLayout/>
		</React.Fragment>
	);
};

export default MyDiplomasPageContainer;