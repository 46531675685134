import React from 'react';
import { TestQrPageLayout } from './TestQrPageLayout';

const TestQrPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<TestQrPageLayout/>
		</React.Fragment>
	);
};

export default TestQrPageContainer;