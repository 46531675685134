import React from 'react';
import { StudentPageLayout } from './StudentPageLayout';

const StudentPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<StudentPageLayout/>
		</React.Fragment>
	);
};

export default StudentPageContainer;