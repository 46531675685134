export const employerData = {
    "phone":{
        'ru': 'Номер телефона',
        'kz': 'Телефон номері',
        'en': 'Phone'
    },
    "email": {
        'ru': 'Почта',
        'kz': 'Пошта',
        'en': 'Email'
    },
    "address": {
        'ru': 'Распололжение',
        'kz': 'Мекен жайы',
        'en': 'Location'
    },
};

export const titles = {
    'ru': {
        data: 'Данные организации',
        about: 'О компании',
        apply: 'Откликнуться',
        phone: 'Номер телефона:',
        email: 'Почта:',
        address: 'Адрес:',
        website: 'Сайт:',
    },
    'kz': {
        data: 'Мекеме жайлы деректер',
        about: 'Компания туралы',
        apply: 'Өтініш беру',
        phone: 'Телефон нөмірі:',
        email: 'Пошта:',
        address: 'Мекен жай:',
        website: 'Сайт:',
    },
    'en': {
        data: 'Organization data',
        about: 'About company',
        apply: 'Apply',
        phone: 'Phone:',
        email: 'Email:',
        address: 'Address:',
        website: 'Website:',
    },
}
export const employerNumData = {
    "branches_amount": {
        'ru': 'Кол-во филиалов',
        'kz': 'Филиалдар саны',
        'en': 'Active branches'
    },
    "vacancy_amount": {
        'ru': 'Открытых вакансий',
        'kz': 'Ашық вакансиялар',
        'en': 'Open positions'
    },
    "hired_amount": {
        "ru": "Нанято сотрудников",
        "kz": "Тағайындалған жұмысшылар",
        "en": "Hired employees"
    },
};