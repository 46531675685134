import React from 'react';

import { UniversityDetailsPage } from './UniversityDetailsPage'

const UniversityProfileContainer: React.FC = () => {

	return (
		<React.Fragment>
			<UniversityDetailsPage />
		</React.Fragment>
	);
};

export default UniversityProfileContainer;