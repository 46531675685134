export const localization = {
    "kz": {
        title: "Менің Дипломдарым",
        noDiplomas: {
            generateDiplomas: "Диплом шығару",
            dontHave: "Сізде диплом жоқ",
            toAdd: "Диплом қосу үшін форманы толтырып, төлем жасаныз",
        },
        form: {
            title: "Менің Дипломдарым / Диплом шығару",
            back: "Артқа",
            name: "ФИО",
            namePlaceholder: "Толық аты-жөніңізді енгізіңіз",
            specialty: "Мамандық",
            specialtyPlaceholder: "Мамандығын енгізіңіз",
            degree: "Дәреже",
            degreePlaceholder: "Бакалавр",
            iin: "ЖСН",
            iinPlaceholder: "ЖСН",
            phoneNumber: "Телефон нөмірі",
            email: "Электронды пошта",
            gender: "Жыныс",
            genderPlaceholder: "Жынысын таңдаңыз",
            residence: "Тұру орны",
            residencePlaceholder: "Адресінізді енгізіңіз",
            continue: "Алға өту",
        },
    },
    "ru":{
        title: "Мои Дипломы",
        noDiplomas: {
            generateDiplomas: "  Выпустить диплом",
            dontHave: "У вас нет дипломов",
            toAdd: "Чтобы добавить диплом, необходимо заполнить форму и оплатить",
        },
        form: {
            title: "Мои Дипломы / Выпустить диплом",
            back: "Назад",
            name: "ФИО",
            namePlaceholder: "Полное имя",
            specialty: "Специальность",
            specialtyPlaceholder: "Специализация",
            degree: "Степень",
            degreePlaceholder: "Бакалавр",
            iin: "ИИН",
            iinPlaceholder: "Напишите",
            phoneNumber: "Номер телефона",
            email: "Почта",
            gender: "Пол",
            genderPlaceholder: "Выберите пол",
            residence: "Место проживания",
            residencePlaceholder: "Напишите адрес",
            continue: "Продолжить",
        }
    },
    "en": {
        title: "My Diplomas",
        noDiplomas: {
            generateDiplomas: "Generate Diploma",
            dontHave: "You don't have any diplomas",
            toAdd: "To add a diploma, fill out the form and make the payment",
        },
        form: {
            title: "My Diplomas / Generate Diploma",
            back: "Back",
            name: "Full Name",
            namePlaceholder: "Enter full name",
            specialty: "Specialty",
            specialtyPlaceholder: "Enter specialization",
            degree: "Degree",
            degreePlaceholder: "Bachelor",
            iin: "IIN",
            iinPlaceholder: "Enter IIN",
            phoneNumber: "Phone Number",
            email: "Email",
            gender: "Gender",
            genderPlaceholder: "Choose Gender",
            residence: "Place of Residence",
            residencePlaceholder: "Enter address",
            continue: "Continue",
        }
    }
}