import React from 'react';
import { EmployerPageLayout } from './EmployerPageLayout';

const EmployerPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<EmployerPageLayout/>
		</React.Fragment>
	);
};

export default EmployerPageContainer;