import React from 'react';

import { SchoolPageLayout } from './SchoolPageLayout';

const SchoolPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<SchoolPageLayout/>
		</React.Fragment>
	);
};

export default SchoolPageContainer;