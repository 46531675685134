export const specialities = {
	"kz": [
		{ id: 1, name: "Қимиялық материалдардың технологиясы" },
		{ id: 2, name: "Геология және пайдаланыстық тамырларды тарату" },
		{ id: 3, name: "Маркетинг" },
		{ id: 4, name: "Менеджмент" },
		{ id: 5, name: "Тіркеу және аудит" },
		{ id: 6, name: "Ұжымдық" },
		{ id: 7, name: "Ұжымдық және экономика" },
		{ id: 8, name: "Экономика" },
		{ id: 9, name: "Бизнесдегі ақпараттық жүйелер" },
		{ id: 10, name: "Экономикалық-математикалық және компьютерлік модельдеу" },
		{ id: 11, name: "Математикалық және компьютерлік модельдеу" },
		{ id: 12, name: "Кемеулік" },
		{ id: 13, name: "Кемеулік энергетикалық орнытасуды өңдеу" },
		{ id: 14, name: "Деу-техника және технология" },
		{ id: 15, name: "Автоматизация және басқару" },
		{ id: 16, name: "Есептеу техникасы және бағдарламалық қамтамасыз ету" },
		{ id: 17, name: "Ақпараттық жүйелер" },
		{ id: 18, name: "Нефтегаз ісі" },
		{ id: 19, name: "Программалық жасақтар" },
		{ id: 20, name: "Компьютерлік ғылым және деректі анализ" },
		{ id: 21, name: "ИТ-те менеджмент" },
		{ id: 22, name: "Проектті басқару" },
		{ id: 23, name: "Басшылар үшін бизнес әкімшілігі" },
		{ id: 24, name: "Материалдар және жаңа материалдар технологиясы" },
		{ id: 25, name: "Наноматериалдар және нанотехнологиялар" },
		{
			"id": 26,
			"name": "Құрылыс инженериясы"
		},
		{
			"id": 27,
			"name": "Көлік құрылысы"
		},
		{
			"id": 28,
			"name": "Математикалық экономика және мәліметтерді талдау"
		},
		{
			"id": 29,
			"name": "Автоматтандыру және роботтандыру"
		},
		{
			"id": 30,
			"name": "Электр техникасы және энергетика"
		},
		{
			"id": 31,
			"name": "Сәулет"
		},
		{
			"id": 32,
			"name": "Көмірсутекті қосылыстардың химиялық инженериясы"
		},
		{
			"id": 33,
			"name": "Сәулет және қала құрылысы"
		},
		{
			"id": 34,
			"name": "Electronic and Electrical Engineering"
		},
		{
			"id": 35,
			"name": "Автоматтандыру және роботтандыру"
		},
		{
			"id": 36,
			"name": "Қолданбалы және инженерлік физика"
		},
		{
			"id": 37,
			"name": "Телекоммуникация"
		},
		{
			"id": 38,
			"name": "Робототехника және мехатроника"
		},
		{
			"id": 39,
			"name": "Құрылыс және құрылыс материалдары мен құрылымдарын өндіру"
		},
		{
			"id": 40,
			"name": "Көлік, көліктік техника және технологиялар"
		},
		{
			"id": 41,
			"name": "Геология және қатты пайдалы қазба кенорындарын барлау"
		},
		{
			"id": 42,
			"name": "Құрылыс және құрылыс материалдары мен құрылымдарын өндіру"
		},
		{
			"id": 43,
			"name": "Дизайн"
		},
		{
			"id": 44,
			"name": "Software Engineering"
		},
		{
			"id": 45,
			"name": "Телекоммуникация"
		},
		{
			"id": 46,
			"name": "Эксплуатациялық-сервистік инженерия"
		},
		{
			"id": 47,
			"name": "Ақпараттық қауіпсіздікті кешенді қамтамасыз ету"
		},
		{
			"id": 48,
			"name": "Көлік құрылысы"
		},
		{
			"id": 49,
			"name": "Геокеңістіктік цифрлік инженерия"
		},
		{
			"id": 50,
			"name": "Көлік, көліктік техника және технологиялар"
		},
		{
			"id": 51,
			"name": "Химиялық және биохимиялық инженерия"
		},
		{
			"id": 52,
			"name": "Ақпараттық қауіпсіздікті кешенді қамтамасыз ету"
		},
		{
			"id": 53,
			"name": "Математикалық экономика және мәліметтерді талдау"
		},
		{
			"id": 54,
			"name": "Машина жасау өндірісін сандандыру"
		},
		{
			"id": 55,
			"name": "Инженерлік физика және материалтану"
		},
		{
			"id": 56,
			"name": "Сәулет"
		},
		{
			"id": 57,
			"name": "Геокеңістіктік цифрлік инженерия"
		},
		{
			"id": 58,
			"name": "Металлургия және пайдалы қазбаларды байыту"
		},
		{
			"id": 59,
			"name": "Тау-кен инженериясы"
		},
		{
			"id": 60,
			"name": "Көмірсутекті қосылыстардың химиялық инженериясы"
		},
		{
			"id": 61,
			"name": "Мұнай-газ және кен Геофизикасы"
		},
		{
			"id": 62,
			"name": "Электр техникасы және энергетика"
		},
		{
			"id": 63,
			"name": "Инженерлік жүйелер және желілер"
		},
		{
			"id": 64,
			"name": "Өнеркәсіптік инженерия"
		},
		{
			"id": 65,
			"name": "Ақпараттық қауіпсіздік"
		},
		{
			"id": 66,
			"name": "Мұнай және газ геологиясы"
		},
		{
			"id": 67,
			"name": "Тау-кен инженериясы"
		},
		{
			"id": 68,
			"name": "Компьютерлік ғылымдар"
		},
		{
			"id": 69,
			"name": "Өндірістегі гигиена және еңбек қорғау"
		},
		{
			"id": 70,
			"name": "Кибернетика және жасанды интеллект"
		},
		{
			"id": 71,
			"name": "Жобалық менеджмент"
		},
		{
			"id": 72,
			"name": "Өнеркәсіптік инженерия"
		},
		{
			"id": 73,
			"name": "Мұнай инженериясы"
		},
		{
			"id": 74,
			"name": "Геология және пайдалы қазбалар кен орындарын барлау"
		},
		{
			"id": 75,
			"name": "Алматыгенплан Q-Lab"
		},
		{
			"id": 76,
			"name": "Көліктік инженерия"
		},
		{
			"id": 77,
			"name": "Геокеңістіктік цифрлік инженерия"
		},
		{
			"id": 78,
			"name": "Құрылыс"
		},
		{
			"id": 79,
			"name": "Геодезия және картография"
		},
		{
			"id": 80,
			"name": "Мұнай-газ және кен геофизикасы"
		},
		{
			"id": 81,
			"name": "Металлургия және пайдалы қазбаларды байыту"
		},
		{
			"id": 82,
			"name": "Executive MBA"
		},
		{
			"id": 83,
			"name": "Сәулет және дизайн"
		},
		{
			"id": 84,
			"name": "Геокеңістіктік цифрлік инженерия"
		},
		{
			"id": 85,
			"name": "Су ресурстары және суды пайдалану"
		},
		{
			"id": 86,
			"name": "Electronic and Electrical Engineering"
		},
		{
			"id": 87,
			"name": "Machine Learning & Data Science"
		},
		{
			"id": 88,
			"name": "Инженерлік экология"
		},
		{
			"id": 89,
			"name": "Өнеркәсіптік инженерия"
		},
		{
			"id": 90,
			"name": "Логистика"
		},
		{
			"id": 91,
			"name": "Биомедициналық инженерия"
		},
		{
			"id": 92,
			"name": "Химиялық және биохимиялық инженерия"
		},
		{
			"id": 93,
			"name": "Машиналар мен жабдықтардың сандық инженериясы"
		},
		{
			"id": 94,
			"name": "Сәулет және дизайн"
		},
		{
			"id": 95,
			"name": "Робототехника және мехатроника"
		},
		{
			"id": 96,
			"name": "Металлургия және пайдалы қазбаларды байыту"
		},
		{
			"id": 97,
			"name": "Computer Science"
		},
		{
			"id": 98,
			"name": "Аддитивті өндіріс"
		},
		{
			"id": 99,
			"name": "Management of information systems"
		},
		{
			"id": 100,
			"name": "Геология және пайдалы қазбалар кен орындарын барлау"
		},
		{
			"id": 101,
			"name": "Математикалық экономика және мәліметтерді талдау"
		},
		{
			"id": 102,
			"name": "Мұнай инженериясы"
		},
		{
			"id": 103,
			"name": "Инженерлік механика"
		},
		{
			"id": 104,
			"name": "Математикалық және компьютерлік модельдеу"
		},
		{
			"id": 105,
			"name": "Management of information systems"
		},
		{
			"id": 106,
			"name": "Petroleum engineering"
		},
		{
			"id": 107,
			"name": "Энергетика"
		},
		{
			"id": 108,
			"name": "Робототехника және мехатроника"
		},
		{
			"id": 109,
			"name": "Көлік қызметтері"
		},
		{
			"id": 110,
			"name": "Химиялық және биохимиялық инженерия"
		},
		{
			"id": 111,
			"name": "Технологиялық машиналар және жабдықтар (салалар бойынша)"
		},
		{
			"id": 112,
			"name": "Инженерлік физика және материалтану"
		}
	],
	"ru": [
		{ id: 1, name: "Химическая технология органических веществ", },
		{ id: 2, name: "Геология и разведка месторождений полезных ископаемых", },
		{ id: 3, name: "Маркетинг", },
		{ id: 4, name: "Менеджмент", },
		{ id: 5, name: "Учет и аудит", },
		{ id: 6, name: "Финансы", },
		{ id: 7, name: "Финансы и экономика", },
		{ id: 8, name: "Экономика", },
		{ id: 9, name: "Информационные системы в бизнесе", },
		{ id: 10, name: "Экономико-математическое и компьютерное моделирование", },
		{ id: 11, name: "Математическое и компьютерное моделирование", },
		{ id: 12, name: "Судовождение", },
		{ id: 13, name: "Эксплуатация судовых энергетических установок", },
		{ id: 14, name: "Морская техника и технологии", },
		{ id: 15, name: "Автоматизация и управление", },
		{ id: 16, name: "Вычислительная техника и программное обеспечение", },
		{ id: 17, name: "Информационные системы", },
		{ id: 18, name: "Нефтегазовое дело", },
		{ id: 19, name: "Программная инженерия", },
		{ id: 20, name: "Компьютерная наука и аналитика данных", },
		{ id: 21, name: "Менеджмент в ИТ", },
		{ id: 22, name: "Управление проектами", },
		{ id: 23, name: "Деловое администрирование для руководителей", },
		{ id: 24, name: "Материаловедение и технология новых материалов", },
		{ id: 25, name: "Наноматериалы и нанотехнологии", },
		{
            "id": 26,
            "name": "Строительная инженерия"
        },
        {
            "id": 27,
            "name": "Транспортное строительство"
        },
        {
            "id": 28,
            "name": "Математическая экономика и анализ данных"
        },
        {
            "id": 29,
            "name": "Автоматизация и роботизация"
        },
        {
            "id": 30,
            "name": "Электротехника и энергетика"
        },
        {
            "id": 31,
            "name": "Архитектура"
        },
        {
            "id": 32,
            "name": "Химическая инженерия углеводородных соединений"
        },
        {
            "id": 33,
            "name": "Архитектура и градостроительство"
        },
        {
            "id": 34,
            "name": "Electronic and Electrical Engineering"
        },
        {
            "id": 35,
            "name": "Автоматизация и роботизация"
        },
        {
            "id": 36,
            "name": "Прикладная и инженерная физика"
        },
        {
            "id": 37,
            "name": "Телекоммуникация"
        },
        {
            "id": 38,
            "name": "Робототехника и мехатроника"
        },
        {
            "id": 39,
            "name": "Строительство и производство строительных материалов и конструкций"
        },
        {
            "id": 40,
            "name": "Транспорт, транспортная техника и технологии"
        },
        {
            "id": 41,
            "name": "Геология и разведка месторождений твердых полезных ископаемых"
        },
        {
            "id": 42,
            "name": "Строительство и производство строительных материалов и конструкций"
        },
        {
            "id": 43,
            "name": "Дизайн"
        },
        {
            "id": 44,
            "name": "Software Engineering"
        },
        {
            "id": 45,
            "name": "Телекоммуникация"
        },
        {
            "id": 46,
            "name": "Эксплуатационно-сервисная инженерия"
        },
        {
            "id": 47,
            "name": "Комплексное обеспечение информационной безопасности"
        },
        {
            "id": 48,
            "name": "Транспортное строительство"
        },
        {
            "id": 49,
            "name": "Геопространственная цифровая инженерия"
        },
        {
            "id": 50,
            "name": "Транспорт, транспортная техника и технологии"
        },
        {
            "id": 51,
            "name": "Химическая и биохимическая инженерия"
        },
        {
            "id": 52,
            "name": "Комплексное обеспечение информационной безопасности"
        },
        {
            "id": 53,
            "name": "Математическая экономика и анализ данных"
        },
        {
            "id": 54,
            "name": "Цифровизация машиностроительного производства"
        },
        {
            "id": 55,
            "name": "Инженерная физика и материаловедение"
        },
        {
            "id": 56,
            "name": "Архитектура"
        },
        {
            "id": 57,
            "name": "Геопространственная цифровая инженерия"
        },
        {
            "id": 58,
            "name": "Металлургия и обогащение полезных ископаемых"
        },
        {
            "id": 59,
            "name": "Горная инженерия"
        },
        {
            "id": 60,
            "name": "Химическая инженерия углеводородных соединений"
        },
        {
            "id": 61,
            "name": "Нефтегазовая и рудная геофизика"
        },
        {
            "id": 62,
            "name": "Электротехника и энергетика"
        },
        {
            "id": 63,
            "name": "Инженерные системы и сети"
        },
        {
            "id": 64,
            "name": "Индустриальная инженерия"
        },
        {
            "id": 65,
            "name": "Информационная безопасность"
        },
        {
            "id": 66,
            "name": "Геология нефти и газа"
        },
        {
            "id": 67,
            "name": "Горная инженерия"
        },
        {
            "id": 68,
            "name": "Информатика"
        },
        {
            "id": 69,
            "name": "Гигиена и охрана труда на производстве"
        },
        {
            "id": 70,
            "name": "Кибернетика и искусственный интеллект"
        },
        {
            "id": 71,
            "name": "Проектный менеджмент"
        },
        {
            "id": 72,
            "name": "Индустриальная инженерия"
        },
        {
            "id": 73,
            "name": "Нефтяная инженерия"
        },
        {
            "id": 74,
            "name": "Геология и разведка месторождений полезных ископаемых"
        },
        {
            "id": 75,
            "name": "Алматыгенплан Q-Lab"
        },
        {
            "id": 76,
            "name": "Транспортная инженерия"
        },
        {
            "id": 77,
            "name": "Геопространственная цифровая инженерия"
        },
        {
            "id": 78,
            "name": "Строительство"
        },
        {
            "id": 79,
            "name": "Геодезия и картография"
        },
        {
            "id": 80,
            "name": "Нефтегазовая и рудная геофизика"
        },
        {
            "id": 81,
            "name": "Металлургия и обогащение полезных ископаемых"
        },
        {
            "id": 82,
            "name": "Executive MBA"
        },
        {
            "id": 83,
            "name": "Архитектура и дизайн"
        },
        {
            "id": 84,
            "name": "Геопространственная цифровая инженерия"
        },
        {
            "id": 85,
            "name": "Водные ресурсы и водопользование"
        },
        {
            "id": 86,
            "name": "Electronic and Electrical Engineering"
        },
        {
            "id": 87,
            "name": "Machine Learning & Data Science"
        },
        {
            "id": 88,
            "name": "Инженерная экология"
        },
        {
            "id": 89,
            "name": "Индустриальная инженерия"
        },
        {
            "id": 90,
            "name": "Логистика"
        },
        {
            "id": 91,
            "name": "Биомедицинская инженерия"
        },
        {
            "id": 92,
            "name": "Химическая и биохимическая инженерия"
        },
        {
            "id": 93,
            "name": "Цифровая инженерия машин и оборудования"
        },
        {
            "id": 94,
            "name": "Архитектура и дизайн"
        },
        {
            "id": 95,
            "name": "Робототехника и мехатроника"
        },
        {
            "id": 96,
            "name": "Металлургия и обогащение полезных ископаемых"
        },
        {
            "id": 97,
            "name": "Computer Science"
        },
        {
            "id": 98,
            "name": "Аддитивное производство"
        },
        {
            "id": 99,
            "name": "Management of information systems"
        },
        {
            "id": 100,
            "name": "Геология и разведка месторождений полезных ископаемых"
        },
        {
            "id": 101,
            "name": "Математическая экономика и анализ данных"
        },
        {
            "id": 102,
            "name": "Нефтяная инженерия"
        },
        {
            "id": 103,
            "name": "Инженерная механика"
        },
        {
            "id": 104,
            "name": "Математическое и компьютерное моделирование"
        },
        {
            "id": 105,
            "name": "Management of information systems"
        },
        {
            "id": 106,
            "name": "Petroleum engineering"
        },
        {
            "id": 107,
            "name": "Энергетика"
        },
        {
            "id": 108,
            "name": "Робототехника и мехатроника"
        },
        {
            "id": 109,
            "name": "Транспортные услуги"
        },
        {
            "id": 110,
            "name": "Химическая и биохимическая инженерия"
        },
        {
            "id": 111,
            "name": "Технологические машины и оборудование (по отраслям)"
        },
        {
            "id": 112,
            "name": "Инженерная физика и материаловедение"
        }
	],
	"en": [
		{ id: 1, name: "Chemical Technology of Organic Substances" },
		{ id: 2, name: "Geology and Prospecting of Mineral Deposits" },
		{ id: 3, name: "Marketing" },
		{ id: 4, name: "Management" },
		{ id: 5, name: "Accounting and Auditing" },
		{ id: 6, name: "Finance" },
		{ id: 7, name: "Finance and Economics" },
		{ id: 8, name: "Economics" },
		{ id: 9, name: "Information Systems in Business" },
		{ id: 10, name: "Economic-Mathematical and Computer Modeling" },
		{ id: 11, name: "Mathematical and Computer Modeling" },
		{ id: 12, name: "Navigation" },
		{ id: 13, name: "Operation of Ship Power Plants" },
		{ id: 14, name: "Maritime Technology and Technology" },
		{ id: 15, name: "Automation and Control" },
		{ id: 16, name: "Computer Engineering and Software" },
		{ id: 17, name: "Information Systems" },
		{ id: 18, name: "Oil and Gas Business" },
		{ id: 19, name: "Software Engineering" },
		{ id: 20, name: "Computer Science and Data Analytics" },
		{ id: 21, name: "IT Management" },
		{ id: 22, name: "Project Management" },
		{ id: 23, name: "Business Administration for Executives" },
		{ id: 24, name: "Materials Science and Technology of New Materials" },
		{ id: 25, name: "Nanomaterials and Nanotechnology" },
		{
            "id": 26,
            "name": "Civil engineering"
        },
        {
            "id": 27,
            "name": "Transport construction"
        },
        {
            "id": 28,
            "name": "Mathematical economics and data analysis"
        },
        {
            "id": 29,
            "name": "Automation and robotization"
        },
        {
            "id": 30,
            "name": "Electrical engineering and power engineering"
        },
        {
            "id": 31,
            "name": "Architecture"
        },
        {
            "id": 32,
            "name": "Hydrocarbon  engineering"
        },
        {
            "id": 33,
            "name": "Architecture and urban planning"
        },
        {
            "id": 34,
            "name": "Electronic and Electrical Engineering"
        },
        {
            "id": 35,
            "name": "Automation and robotization"
        },
        {
            "id": 36,
            "name": "Applied and engineering physics"
        },
        {
            "id": 37,
            "name": "Telecommunication"
        },
        {
            "id": 38,
            "name": "Robotics and mechatronics"
        },
        {
            "id": 39,
            "name": "Сivil engineering and production of building materials and structures"
        },
        {
            "id": 40,
            "name": "Transport, transport technique and technologies"
        },
        {
            "id": 41,
            "name": "Geology and exploration of solid mineral deposits"
        },
        {
            "id": 42,
            "name": "Сivil engineering and production of building materials and structures"
        },
        {
            "id": 43,
            "name": "Design"
        },
        {
            "id": 44,
            "name": "Software Engineering"
        },
        {
            "id": 45,
            "name": "Telecommunication"
        },
        {
            "id": 46,
            "name": "Operational and Service Engineering"
        },
        {
            "id": 47,
            "name": "Comprehensive information security"
        },
        {
            "id": 48,
            "name": "Transport construction"
        },
        {
            "id": 49,
            "name": "Geospatial Digital Engineering"
        },
        {
            "id": 50,
            "name": "Transport, transport technique and technologies"
        },
        {
            "id": 51,
            "name": "Chemical and Biochemical Engineering"
        },
        {
            "id": 52,
            "name": "Comprehensive information security"
        },
        {
            "id": 53,
            "name": "Mathematical economics and data analysis"
        },
        {
            "id": 54,
            "name": "Digitalization of engineering manufacturing"
        },
        {
            "id": 55,
            "name": "Engineering physics and material science"
        },
        {
            "id": 56,
            "name": "Architecture"
        },
        {
            "id": 57,
            "name": "Geospatial Digital Engineering"
        },
        {
            "id": 58,
            "name": "Metallurgy and mineral processing"
        },
        {
            "id": 59,
            "name": "Mining engineering"
        },
        {
            "id": 60,
            "name": "Hydrocarbon  engineering"
        },
        {
            "id": 61,
            "name": "Oil and gas and ore geophysics"
        },
        {
            "id": 62,
            "name": "Electrical engineering and power engineering"
        },
        {
            "id": 63,
            "name": "Engineering systems and networks"
        },
        {
            "id": 64,
            "name": "Industrial engineering"
        },
        {
            "id": 65,
            "name": "Information Security"
        },
        {
            "id": 66,
            "name": "Oil and gas geology"
        },
        {
            "id": 67,
            "name": "Mining engineering"
        },
        {
            "id": 68,
            "name": "Computer Science"
        },
        {
            "id": 69,
            "name": "Occupational Health and Safety"
        },
        {
            "id": 70,
            "name": "Cybernetics and Artificial intelligence"
        },
        {
            "id": 71,
            "name": "Project Management"
        },
        {
            "id": 72,
            "name": "Industrial engineering"
        },
        {
            "id": 73,
            "name": "Petroleum Engineering"
        },
        {
            "id": 74,
            "name": "Geology and exploration of mineral deposits"
        },
        {
            "id": 75,
            "name": "Алматыгенплан Q-Lab"
        },
        {
            "id": 76,
            "name": "Transport Engineering"
        },
        {
            "id": 77,
            "name": "Geospatial Digital Engineering"
        },
        {
            "id": 78,
            "name": "Civil engineering"
        },
        {
            "id": 79,
            "name": "Geodesy and cartography"
        },
        {
            "id": 80,
            "name": "Oil and gas and ore geophysics"
        },
        {
            "id": 81,
            "name": "Metallurgy and mineral processing"
        },
        {
            "id": 82,
            "name": "Executive MBA"
        },
        {
            "id": 83,
            "name": "Architecture and design"
        },
        {
            "id": 84,
            "name": "Geospatial Digital Engineering"
        },
        {
            "id": 85,
            "name": "Water resources and water use"
        },
        {
            "id": 86,
            "name": "Electronic and Electrical Engineering"
        },
        {
            "id": 87,
            "name": "Machine Learning & Data Science"
        },
        {
            "id": 88,
            "name": "Engineering ecology"
        },
        {
            "id": 89,
            "name": "Industrial engineering"
        },
        {
            "id": 90,
            "name": "Logistics"
        },
        {
            "id": 91,
            "name": "Biomedical Engineering"
        },
        {
            "id": 92,
            "name": "Chemical and Biochemical Engineering"
        },
        {
            "id": 93,
            "name": "Digital Engineering of Machines and Equipment"
        },
        {
            "id": 94,
            "name": "Architecture and design"
        },
        {
            "id": 95,
            "name": "Robotics and mechatronics"
        },
        {
            "id": 96,
            "name": "Metallurgy and mineral processing"
        },
        {
            "id": 97,
            "name": "Computer Science"
        },
        {
            "id": 98,
            "name": "Additive Manufacturing"
        },
        {
            "id": 99,
            "name": "Management of information systems"
        },
        {
            "id": 100,
            "name": "Geology and exploration of mineral deposits"
        },
        {
            "id": 101,
            "name": "Mathematical economics and data analysis"
        },
        {
            "id": 102,
            "name": "Petroleum Engineering"
        },
        {
            "id": 103,
            "name": "Mechanical Engineering"
        },
        {
            "id": 104,
            "name": "Mathematical and computer modeling"
        },
        {
            "id": 105,
            "name": "Management of information systems"
        },
        {
            "id": 106,
            "name": "Petroleum engineering"
        },
        {
            "id": 107,
            "name": "Power engineering"
        },
        {
            "id": 108,
            "name": "Robotics and Mechatronics"
        },
        {
            "id": 109,
            "name": "Transport services"
        },
        {
            "id": 110,
            "name": "Chemical and Biochemical Engineering"
        },
        {
            "id": 111,
            "name": "Technological machines and equipment (by industry)"
        },
        {
            "id": 112,
            "name": "Engineering physics and material science"
        }
	]

};
export const regions = {
	"kz": [
		{ id: 1, name: "Ақмола облысы" },
		{ id: 2, name: "Ақтөбе облысы" },
		{ id: 3, name: "Алматы облысы" },
		{ id: 4, name: "Алматы" },
		{ id: 5, name: "Астана" },
		{ id: 6, name: "Атырау облысы" },
		{ id: 7, name: "Шығыс Қазақстан облысы" },
		{ id: 8, name: "Жамбыл облысы" },
		{ id: 9, name: "Жезқазған облысы" },
		{ id: 10, name: "Батыс Қазақстан облысы" },
		{ id: 11, name: "Қазақстан" },
		{ id: 12, name: "Қарағанды облысы" },
		{ id: 13, name: "Қарақалпақстан" },
		{ id: 14, name: "Қостанай облысы" },
		{ id: 15, name: "Қызылорда облысы" },
		{ id: 16, name: "Маңғыстау облысы" },
		{ id: 17, name: "Павлодар облысы" },
		{ id: 18, name: "Солтүстік Қазақстан облысы" },
		{ id: 19, name: "Талдықорған облысы" },
		{ id: 20, name: "Түркістан облысы" },
		{ id: 21, name: "Шымкент" },
	],
	"ru": [{ id: 1, name: "Акмолинская обл.", },
	{ id: 2, name: "Актюбинская обл.", },
	{ id: 3, name: "Алматинская область", },
	{ id: 4, name: "Алматы", },
	{ id: 5, name: "Астана", },
	{ id: 6, name: "Атырауская обл.", },
	{ id: 7, name: "Восточно-Казахстанская обл.", },
	{ id: 8, name: "Жамбылская обл.", },
	{ id: 9, name: "Жезказганская обл.", },
	{ id: 10, name: "Западно-Казахстанская обл.", },
	{ id: 11, name: "Казахстан", },
	{ id: 12, name: "Карагандинская обл.", },
	{ id: 13, name: "Каракалпакия", },
	{ id: 14, name: "Костанайская обл.", },
	{ id: 15, name: "Кызылординская обл.", },
	{ id: 16, name: "Мангыстауская обл.", },
	{ id: 17, name: "Павлодарская обл.", },
	{ id: 18, name: "Северо-Казахстанская обл.", },
	{ id: 19, name: "Талдыкорганская обл.", },
	{ id: 20, name: "Туркестанская обл.", },
	{ id: 21, name: "Шымкент", },],
	"en": [
		{ id: 1, name: "Akmola Region" },
		{ id: 2, name: "Aktobe Region" },
		{ id: 3, name: "Almaty Region" },
		{ id: 4, name: "Almaty" },
		{ id: 5, name: "Nur-Sultan" },
		{ id: 6, name: "Atyrau Region" },
		{ id: 7, name: "East Kazakhstan Region" },
		{ id: 8, name: "Zhambyl Region" },
		{ id: 9, name: "Zhezkazgan Region" },
		{ id: 10, name: "West Kazakhstan Region" },
		{ id: 11, name: "Kazakhstan" },
		{ id: 12, name: "Karaganda Region" },
		{ id: 13, name: "Karakalpakstan" },
		{ id: 14, name: "Kostanay Region" },
		{ id: 15, name: "Kyzylorda Region" },
		{ id: 16, name: "Mangystau Region" },
		{ id: 17, name: "Pavlodar Region" },
		{ id: 18, name: "North Kazakhstan Region" },
		{ id: 19, name: "Taldykorgan Region" },
		{ id: 20, name: "Turkistan Region" },
		{ id: 21, name: "Shymkent" },
	]

};
export const years = [
	{
		id: 1,
		year: 2023,
	},
];

export const degree = [
	{ id: 1, name: "Бакалавр" },
	{ id: 2, name: "Магистр" },
];
export const localization = {
	"kz": {
		MainCard: {
			speciality: "Мамандықтар",
			region: "Облыстар",
			year: "Бітірген жылы",
			filter: "Сүзгі",
			apply: "Қолдану",
			university: "Университет",
			gpa:"Орташа GPA",
			rating:'Рейтинг',
			query: 'Сұрақты енгізіңіз',
			field: 'Салалар',
			scopeOfActivity:'Қызмет саласы',

		}
	},
	"ru": {
		MainCard: {
			speciality: "Специальности",
			region: "Регион",
			year: "Год выпуска",
			filter: "Фильтр",
			apply: "Применить",
			university: "Университет",
			gpa:"Средний GPA",
			rating:'Рейтинг',
			query: "Введите запрос",
			field: 'Области',
			scopeOfActivity:'Сфера деятельности',
		}
	},
	"en": {
		MainCard: {
			speciality: "Specialities",
			region: "Regions",
			year: "Year of Graduation",
			filter: "Filter",
			apply: "Apply",
			university: "University",
			gpa:"Average GPA",
			rating:'Rating',
			query: "Enter query",
			field: 'Fields',
			scopeOfActivity:'Scope of activity',
		}
	},
};

export const universities = {
	"kz": [
		{ id: 1, name: "КБТУ", university_id: 1 },
		{ id: 2, name: "АГП", university_id: 2 },
		{ id: 3, name: "Сатпаев Университет", university_id: 3 },
	],
	"ru": [
		{ id: 1, name: "КБТУ", university_id: 1 },
		{ id: 2, name: "АГП", university_id: 2 },
		{ id: 3, name: "Сатпаев Университет", university_id: 3 },
	],
	"en": [
		{ id: 1, name: "KBTU", university_id: 1 },
		{ id: 2, name: "AGP", university_id: 2 },
		{ id: 3, name: "Satbayev University", university_id: 3 },
	],
}

export const fields = {
	"en": [
		{ "id": 1, "name": "Automotive industry" },
		{ "id": 2, "name": "Hospitality and catering" },
		{ "id": 3, "name": "Public sector" },
		{ "id": 4, "name": "Natural resources and energy" },
		{ "id": 5, "name": "Utilities" },
		{ "id": 6, "name": "Technology and IT" },
		{ "id": 7, "name": "Culture and art" },
		{ "id": 8, "name": "Forestry and wood processing industry" },
		{ "id": 9, "name": "Healthcare and pharmaceuticals" },
		{ "id": 10, "name": "Metallurgy and metalworking" },
		{ "id": 11, "name": "Education" },
		{ "id": 12, "name": "Logistics and transport" },
		{ "id": 13, "name": "Food industry" },
		{ "id": 14, "name": "Industrial equipment and mechanical engineering" },
		{ "id": 15, "name": "Retail trade and services" },
		{ "id": 16, "name": "Agriculture" },
		{ "id": 17, "name": "Marketing, media and advertising" },
		{ "id": 18, "name": "Construction and real estate" },
		{ "id": 19, "name": "Telecommunications and communications" },
		{ "id": 20, "name": "Consumer goods" },
		{ "id": 21, "name": "Asset management and business services" },
		{ "id": 22, "name": "Financial sector" },
		{ "id": 23, "name": "Chemical industry" }
	],
	"kz": [
		{ "id": 1, "name": "Автомобиль өнеркәсібі" },
		{ "id": 2, "name": "Қонақжайлылық және қоғамдық тамақтандыру" },
		{ "id": 3, "name": "Мемлекеттік сектор" },
		{ "id": 4, "name": "Табиғи ресурстар және энергия" },
		{ "id": 5, "name": "Утилиталар" },
		{ "id": 6, "name": "Технология және АТ" },
		{ "id": 7, "name": "Мәдениет және өнер" },
		{ "id": 8, "name": "Орман және ағаш өңдеу өнеркәсібі" },
		{ "id": 9, "name": "Денсаулық сақтау және фармацевтика" },
		{ "id": 10, "name": "Металлургия және металл өңдеу" },
		{ "id": 11, "name": "Білім" },
		{ "id": 12, "name": "Логистика және көлік" },
		{ "id": 13, "name": "Тамақ өнеркәсібі" },
		{ "id": 14, "name": "Өнеркәсіптік жабдық және машина жасау" },
		{ "id": 15, "name": "Бөлшек сауда және қызмет көрсету" },
		{ "id": 16, "name": "Ауыл шаруашылығы" },
		{ "id": 17, "name": "Маркетинг, БАҚ және жарнама" },
		{ "id": 18, "name": "Құрылыс және жылжымайтын мүлік" },
		{ "id": 19, "name": "Телекоммуникация және байланыс" },
		{ "id": 20, "name": "Тұтыну тауарлары" },
		{ "id": 21, "name": "Активтерді басқару және бизнес қызметтері" },
		{ "id": 22, "name": "Қаржы секторы" },
		{ "id": 23, "name": "Химия өнеркәсібі" }
	],
	"ru": [
		{ "id": 1, "name": "Автомобильная индустрия" },
		{ "id": 2, "name": "Гостеприимство и общественное питание" },
		{ "id": 3, "name": "Государственный сектор" },
		{ "id": 4, "name": "Природные ресурсы и энергетика" },
		{ "id": 5, "name": "Коммунальные услуги" },
		{ "id": 6, "name": "Технологии и IT" },
		{ "id": 7, "name": "Культура и искусство" },
		{ "id": 8, "name": "Лесная и деревообрабатывающая промышленность" },
		{ "id": 9, "name": "Здравоохранение и фармацевтика" },
		{ "id": 10, "name": "Металлургия и металлообработка" },
		{ "id": 11, "name": "Образование" },
		{ "id": 12, "name": "Логистика и транспорт" },
		{ "id": 13, "name": "Пищевая промышленность" },
		{ "id": 14, "name": "Промышленное оборудование и машиностроение" },
		{ "id": 15, "name": "Розничная торговля и услуги" },
		{ "id": 16, "name": "Сельское хозяйство" },
		{ "id": 17, "name": "Маркетинг, СМИ и реклама" },
		{ "id": 18, "name": "Строительство и недвижимость" },
		{ "id": 19, "name": "Телекоммуникации и связь" },
		{ "id": 20, "name": "Товары народного потребления" },
		{ "id": 21, "name": "Управление активами и бизнес-услуги" },
		{ "id": 22, "name": "Финансовый сектор" },
		{ "id": 23, "name": "Химическая промышленность" }
	],
};

export const scopeOfActivity = {
	"en": [
		{"id": 1, "name": "Automotive industry"},
		{"id": 2, "name": "Hospitality and catering"},
		{"id": 3, "name": "Public sector"},
	],
	"kz": [
		{"id": 1, "name": "Автомобиль өнеркәсібі"},
		{"id": 2, "name": "Қонақжайлылық және қоғамдық тамақтандыру"},
		{"id": 3, "name": "Мемлекеттік сектор"},
	],
	"ru": [
		{"id": 1, "name": "Автомобильная индустрия"},
		{"id": 2, "name": "Гостеприимство и общественное питание"},
		{"id": 3, "name": "Государственный сектор"},

	],
};