import { apply } from "redux-saga/effects";

export const localization = {
	"kz":{
		Header:{
			filter: "Фильтр",
			searchBar: "Компания атауы",
			searchButton: "Іздеу",
			university: "Жұмыс берушілер",
			apply: 'Өтініш беру',
		},
	},
	"ru":{
		Header:{
			filter: "Фильтр",
			searchBar: "Название компании",
			searchButton: "Поиск",
			university: "Работодатели",
			apply: 'Откликнуться',
		},
	},
	"en":{
		Header:{
			filter: "Filter",
			searchBar: "Company name",
			searchButton: "Search",
			university: "Employers",
			apply: 'Apply'
		},
	},
}