import React from 'react';

import { QLabPageLayout } from './QLabPageLayout'

const QLabPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<QLabPageLayout />
		</React.Fragment>
	);
};

export default QLabPageContainer;