import React from 'react';
import NotFoundPageLayout from './NotFoundPageLayout';

const NotFoundPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<NotFoundPageLayout />
		</React.Fragment>
	);
};

export default NotFoundPageContainer;