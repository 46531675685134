import React from 'react';
import { StudentPublicPageLayout } from './StudentPublicPageLayout';

const StudentPublicPageContainer: React.FC = () => {

	return (
		<React.Fragment>
			<StudentPublicPageLayout/>
		</React.Fragment>
	);
};

export default StudentPublicPageContainer;