import React from 'react';
import { AuthBasePageLayout } from '../AuthPage';
import { MainPageLayout } from './MainPageLayout';

const MainPageContainer: React.FC = () => {

	return (
		<React.Fragment>

			<MainPageLayout />
		</React.Fragment>
	);
};

export default MainPageContainer;